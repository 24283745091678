/* BottomNavbar.css */

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --icon-size: 1.5rem; /* Adjusted for smaller screens */
  --indicator-spacing: calc(var(--icon-size) / 8);
  --border-radius: calc(var(--icon-size) / 4);
  --nav-item-padding: calc(var(--icon-size) / 2);
  --background-color: transparent;
}

.footer-container {
  background-color: transparent;
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius);
  width: 320px; /* Adjusted for smaller screens */
  position: fixed;
  bottom: 0;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  padding: 0.5rem;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative; /* Added */
}

.list a {
  color: #000000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--nav-item-padding);
  position: relative; /* Added */
}

.list .text {
  font-size: 0.7em; /* Adjusted for smaller screens */
  opacity: 0;
  pointer-events: none;
  transition: 250ms ease-in-out;
  position: absolute;
  bottom: calc(0.2 * var(--nav-item-padding)); /* Adjusted for smaller screens */
  transform: translateY(50%);
}

.list .active .text {
  pointer-events: all;
  opacity: 1;
}

.list .icon {
  position: relative;
  transition: 250ms ease-in-out;
}

.list .icon svg {
  fill: currentColor;
  width: var(--icon-size);
  height: var(--icon-size);
  display: block;
}

.list .active .text {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
}

.list .active .icon {
  transform: translateY(calc(-50% - var(--nav-item-padding)));
}

.indicator {
  position: absolute;
  left: calc(var(--position) * (var(--icon-size) + var(--nav-item-padding) * 2) + var(--nav-item-padding) - 60px);
  transition: 250ms ease-in-out;
}



.indicator::after,
.indicator::before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.indicator::after {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  width: calc(var(--icon-size) * 2);
  height: calc(var(--icon-size) * 2);
  top: calc(-1 * var(--icon-size));
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
 
  50% {
    background-position: 100% 50%;
  }
 
  100% {
    background-position: 0% 50%;
  }
}
.indicator::before {
  background-color: var(--background-color);
  width: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
  height: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
  top: calc(-1 * var(--icon-size) - var(--indicator-spacing));
  left: calc(-1 * var(--indicator-spacing));
}

.corners::before {
  content: "";
  box-sizing: content-box;
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  background-color: transparent;
  z-index: 1;
  top: calc(-1 * var(--indicator-spacing));
  left: calc(.2 * var(--indicator-spacing));
  transform: translateX(-100%);
  border-top-right-radius: 100%;
  border-width: calc(var(--indicator-spacing));
  border-color: var(--background-color);
  border-style: solid;
  border-bottom: none;
  border-left: none;
}

.corners::after {
  content: "";
  box-sizing: content-box;
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  background-color: transparent;
  z-index: 1;
  top: calc(-1 * var(--indicator-spacing));
  left: calc(var(--icon-size) * 2 + -.2 * var(--indicator-spacing));
  border-top-left-radius: 100%;
  border-width: calc(var(--indicator-spacing));
  border-color: var(--background-color);
  border-style: solid;
  border-bottom: none;
  border-right: none;
}
.footer-container {
  display: none; /* Initially hide the navbar */
}
@media only screen and (max-width: 767px) {
  /* Show the navbar on screens with a maximum width of 767px */
  .footer-container {
    display: block;
    /* Add your styling for the navbar here */
  }
}