.navbar {
  background-color: rgba(0, 0, 0, 0.8); 
    backdrop-filter: blur(10px);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  .custom-button {
  height: auto;
  width: auto;
  padding: 15px 30px;
  background-color: #fc5400;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; 
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
  .custom-button:hover {
  background-color: #fff;
  color: #fc5400;
  }
  
  .navbar-logo {
  position: absolute;
  top: 0;
  left: -10px;
  margin-right: 20px;
  transform: translate(25%, 50%);
  margin-top: 5px;
  }
  
  
  
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 10px;
    margin-right: 50px; /* Adjusted value to reduce space */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(9, auto);
    gap: 15px;
    list-style: none;
    text-align: center;
    width: 100%; /* Adjusted to full width */
    justify-content: flex-end; /* Updated to flex-end */
    padding: 1rem; /* Added padding for better spacing */
    box-sizing: border-box; /* Ensures padding is included in the total width */
  }
  
  
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  .custom-button {
    height: auto;
    width: auto;
    padding: 15px 30px;
    background-color: #00db5f; /* Copper sulfate blue color */
    color: #000000;
    border: none;
    border-radius: 8px; /* Slightly rounder corners */
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    margin-left: auto; /* Move the button to the right */
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(51, 138, 57, 0.3); /* Subtle box shadow for depth */
  }
  
  .custom-button:hover {
    background-color: #fff;
    color: #169508; /* Inverted colors on hover for contrast */
    box-shadow: 0 6px 12px rgba(51, 101, 138, 0.5); /* Slightly larger shadow on hover */
  }
  
  .nav-item {
    height: 70px;
  }
  @media screen and (max-width: 1024px) {
    .custom-button {
      position: absolute;
      top: 17px;
      right: 10px; /* Adjust the right position to move the button to the rightmost corner */
      margin: 0;
      font-size: 18px;
      padding: 10px 15px;
    }
    .custom-button:hover {
      background-color: #fff;
      color: #000000;
    }
    .menu-icon {
      display: none;
    }
    .nav-links {
      color: #fff;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 12px;
      height: 100%;
      font-size: 13px;
    }
    
    .nav-links:hover {
      border-bottom: 4px solid #fff;
      transition: all 0.2s ease-out;
    } 
  }
  
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
  
    .custom-button {
      height: auto;
      width: auto;
      padding: 15px 30px;
      background-color: #00db5f; /* Copper sulfate blue color */
      color: #000000;
      border: none;
      border-radius: 8px; /* Slightly rounder corners */
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      margin-left: auto; /* Move the button to the right */
      text-decoration: none;
      box-shadow: 0 4px 8px rgba(51, 138, 57, 0.3); /* Subtle box shadow for depth */
    }
    
    .custom-button:hover {
      background-color: #fff;
      color: #169508; /* Inverted colors on hover for contrast */
      box-shadow: 0 6px 12px rgba(51, 101, 138, 0.5); /* Slightly larger shadow on hover */
    }
  
    .nav-item {
      height: 70px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .navbar-logo {
      position: absolute;
      top: 10px;
      left: 45%; /* Center the logo horizontally */
      transform: translateX(-50%);
      font-size: 40px;
    }
  
  
    .nav-links-mobile {
      display: block; 
    }
  
    .navbar-text {
      display: none; /* Hide the navbar text on screens with width up to 320px */
    }
  
  
  
    .custom-button {
      position: absolute;
      top: 17px;
      right: 10px; /* Adjust the right position to move the button to the rightmost corner */
      margin: 0;
      font-size: 18px;
      padding: 10px 15px;
    }       
  }
  
  @media only screen and (max-width: 320px) {
    .navbar-logo {
      position: absolute;
      top: 10px;
      left: 45%; /* Center the logo horizontally */
      transform: translateX(-50%);
      font-size: 40px;
    }
  
    .nav-links-mobile {
      display: block; 
    }
  
    .navbar-text {
      display: none; 
    }
  
  
  
    .custom-button {
      position: absolute;
      top: 17px;
      right: 10px; 
      margin: 0;
      font-size: 18px;
      padding: 10px 15px;
    }   
  }
  
  
