/* Updated styling for the content part of .AboutUs */
.AboutUs .content {
  margin-top: 20px;
  padding: 20px;
  background: linear-gradient(to right, #f9f9f9, #e0e0e0); /* Gradient from light to slightly darker color */
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}


.AboutUs .section {
  margin-bottom: 30px;
}

.AboutUs .section-subheading {
  text-align: center;
  font-size: 2em;
  color: #ff5500; /* Highlight color */
  margin-bottom: 15px; /* Adjust margin for better spacing */
}

.AboutUs .section p {
  font-size: 1.3em;
  line-height: 1.6;
  color: #333; /* Darker text color for better contrast */
  text-align: justify; /* Justify text for a clean look */
}

.AboutUs .section ul {
  list-style-type: disc; /* Use disc bullets for better readability */
  margin-left: 20px;
}

.AboutUs .section li {
  font-size: 1.3em;
  line-height: 1.6;
  color: #333;
}

/* Additional styles for better readability */
.AboutUs .section h2 {
  font-size: 2.5em; /* Slightly increase heading size */
  color: #1a1a1a; /* Darker heading color */
  margin-bottom: 20px;
}
/* Add this to your CSS file or include in-line styles */

/* Add this to your CSS file or include in-line styles */

.styled-link {
  color: #4a90e2; /* Link color */
  text-decoration: none; /* Remove default underline */
  font-weight: bold; /* Optionally, make the text bold */
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions on hover */

  /* Optional: Add more styles as needed */
}

.styled-link:hover {
  background-color: #4a90e2; /* Change background color on hover */
  color: #ffffff; /* Change text color on hover */
}

.link-icon {
  margin-right: 10px;
}
.material-icons {
  font-size: 24px; /* Adjust the size as needed */
  margin-right: 10px;
}
