.hero-section {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(255, 81, 0, 0.927);
    object-fit: contain;
  }
  
  
  .hero-section h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  .content p.quote {
  font-style: italic;
  font-size: 1.2em;
  color: #000000; /* Change the color as needed */
  text-align: center;
  margin-top: 20px;
  }
  .hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #87ceeb, #6ab7ff, #4cb8d1, #3aa3d5, #0e45be, #284fe4, #66c2ff);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    color: #fff;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  
  .hero-container > h1 {
    font-size: 80px;
    margin-top: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out; /* Adjusted duration */
  }
  
  /* Add the loaded class to trigger the animation */
  .hero-container.loaded > h1 {
    opacity: 1;
    transform: translateY(0);
  }
  
  
  
  .hero--btns {
    margin-top: 32px;
    margin-right: -700px;
    justify-content: center;
    align-items: center;
  }
  
  /*for mobile screens*/
  @media only screen and (max-width: 768px) {
  .hero-container > h1 {
    color: rgb(255, 255, 255);
    font-size: 60px; /* Adjust the font size as needed */
    margin-top: 100px;
  }
  .hero-container.loaded > h1 {
    margin-top: 0;
  }
  }
  
  
  
  
  @media screen and (max-width: 375px) {
  .hero-section {
    height: 60vh;
    width: 100%;
    box-shadow: inset 0 0 0 1000px rgba(255, 81, 0, 0.927);
  }
  
  .hero-section h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .hero-container {
    height: 100vh;
  }
  
  .hero-container > h1 {
    font-size: 40px;
    margin-top: -50px;
  }
  .hero-container.loaded > h1 {
    margin-top: 0;
  }
  }
