.Governance {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ec3515;
    box-shadow: #ff6347;
    position: relative;
}

.background-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/images/Governance.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Governance h1 {
    font-size: 8rem;
    margin-bottom: 15px;
    color: #000000;
}

.Governance p {
    color: #000000;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 20px;
}

.Governance .hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Governance .hero-container > h1 {
    color: #000;
    font-size: 2.5rem;
    margin-top: -10px;
}

/* Exclude p tag within hero-container from the general p styles */
.Governance .hero-container p {
    margin-top: 0;
}

@media screen and (max-width: 425px) {
    .Governance h1 {
        font-size: 4rem;
        margin-bottom: 15px;
        color: #000000;
    }
}
