.ContactUs {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ContactUs h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #000000;
}

.ContactUs p {
  color: #000000;
  margin-bottom: 20px;
}

.ContactDetails {
  color: #555;
  margin-bottom: 20px;
}

.ContactUs button {
  background-color: #ff9100;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-out;
}

.ContactUs button:hover {
  background-color: #ffad42;
}

