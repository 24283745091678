/* Privacy.css */
.container {
    /* Your container styles */
  }
  
  .section-heading {
    /* Your section-heading styles */
  }
  
  .contents {
    /* Your contents styles */
  
    /* Add this style to align h2 elements to the left */
    h2 {
      text-align: left;
    }
  }
  /* Add this to your Privacy.css or any relevant CSS file */
p {
    font-size: 18px; /* Adjust the font size as needed */
    /* You can also add other styling properties if desired */
  }
  
  ul {
    list-style-type: disc; /* This sets the bullet point style */
    margin-left: 20px; /* Adjust the left margin for better spacing */
  } 