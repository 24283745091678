/* Styling for the content part of DonateUs */
.DonateUs .content {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff; /* Add a light background color */
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.DonateUs .section {
    margin-bottom: 40px;
}

.DonateUs .section-subheading {
    text-align: center;
    font-size: 1.8em;
    color: #ff5500;
    margin-bottom: 10px; /* Adjust margin for better spacing */
}

.DonateUs .section p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    text-align: justify; /* Justify text for a clean look */
}

.DonateUs .section ul {
    list-style-type: square;
    margin-left: 20px;
}

.DonateUs .section li {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
}

/* Additional styles for better readability */
.DonateUs .section h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 15px;
}
.image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  
  .custom-image {
    max-width: 100%; /* You can adjust this based on your preference */
    height: auto;
    /* Add any additional styling for positioning, such as margin or padding */
  }
  @media screen and (max-width: 768px) {
    .custom-image {
      max-width: 80%; /* Adjust the percentage based on your preference */
    }
  }   
