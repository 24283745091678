/* Reset and Box Sizing */
body, h1, h2, p, div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Font Styles */
body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

/* Container Styles */
/* Container Styles */
.container h1 {
  font-size: 50px;
  margin-bottom: 15px;
  color: #fff; /* White font color for better visibility */
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding: 50px;
  height: 50vh;
  text-align: center;
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
 
  50% {
    background-position: 100% 50%;
  }
 
  100% {
    background-position: 0% 50%;
  }
}


/* Header Styles */
.container h1 {
  font-size: 4em; /* Adjust the font size as needed */
  margin-bottom: 15px;
  color: #000000;
  padding: 50px;
  height: 50vh;
  text-align: center;
  width: 100%;
}


/* Content Styles */
.container .content {
  background-color: #ffffff; /* Content background color */
  padding: 20px; /* Add padding for spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Content box-shadow */
  margin-bottom: 20px; /* Add margin to separate sections */
}

.container .content p {
  font-size: 1.2em; /* Adjust the font size as needed */
  text-align: left; /* Adjust the text alignment as needed */
  margin-bottom: 15px; /* Add spacing between paragraphs */
  font-family: 'Poppins', sans-serif;
}

.container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .content img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: 15px; /* Add spacing between the image and the paragraph */
  border-radius: 8px; /* Add border-radius for a rounded corner effect */
}

/* Additional Section Styles */
.container .additional-section {
  background-color: #ffffff; /* Additional section background color */
  padding: 20px; /* Add padding for spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Additional section box-shadow */
  margin-bottom: 20px; /* Add margin to separate sections */
}

.container .additional-section p {
  font-size: 1.2em; /* Adjust the font size as needed */
  text-align: left; /* Adjust the text alignment as needed */
  margin-bottom: 15px; /* Add spacing between paragraphs */
}
/* Add button styles */
.container .cta-button {
  background-color: #ec3515;
  color: #ffffff;
  padding: 10px 20px;
  border: 2px solid #ec3515; /* Add a subtle border */
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; /* Remove default link underline */
  font-size: 1em;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  /* Add box shadow for a subtle lift */
  box-shadow: 0 2px 4px rgba(236, 53, 21, 0.2);
}

.container .cta-button:hover {
  background-color: #ff6347;
  color: #ffffff; /* Change text color on hover for better contrast */
}

.dropdown-box {
  display: none;
  position: absolute;
  top: 50px; /* Adjust the top position as needed */
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 1;
}


/* Style the sections as needed */
section {
  margin-top: 20px;
}

/* Adjust styles for smaller screens */
@media screen and (max-width: 960px) {
  .dropdown-box {
    top: 80px; /* Adjust the top position for mobile menu */
    width: 100%; /* Make the dropdown box full-width */
    left: 0;
  }

  .dropdown-box a {
    padding: 10px 20px; /* Adjust padding for better mobile experience */
  }
}

@media screen and (max-width: 320px) {
  body {
    font-family: 'Nanum Gothic', sans-serif;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .container {
    max-width: 100%;
    margin: 0;
    padding: 0px;
  }
  
  .container h1 {
    font-size: 50px;
    margin-bottom: 15px;
    color: #000000; /* White font color for better visibility */
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    padding: 50px;
    height: 50vh;
    text-align: center;
    width: 100%;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
   
    50% {
      background-position: 100% 50%;
    }
   
    100% {
      background-position: 0% 50%;
    }
  }
  
  .container .content {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .container .additional-section {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .container .additional-section p {
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 15px;
  }

  .container .cta-button {
    background-color: #ec3515;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1em;
    transition: background-color 0.3s ease-in-out;
    margin-right: 0%;
    margin-left: 100px;
  }

  .container .cta-button:hover {
    background-color: #ff6347;
  }
}
